@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Comfortaa:wght@300&family=Krona+One&display=swap&family=Montserrat:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Comfortaa:wght@300&family=Krona+One&display=swap&family=Montserrat:wght@600&display=swap);
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

*{
  box-sizing: border-box;
}
*:before,
*:after {
 box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.App {
  text-align: center;
  background-color: rgb(13,17,40);
  display:flex;
  min-height:100vh;
  flex-direction:column;
  box-sizing: border-box;
  padding:0;
  margin:0;
}
.App:hover{
  cursor:default;
}
hr{
  width:100%;
  margin:0;
}
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/
.navBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 50px;
  z-index: 1;
}

.navBar .content {
  font-family: "Noto Sans", sans-serif;
}

.navBar .titles {
  font-family: "Playfair Display", serif;
}

.navBar > ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.navBar > ul > #homeLI {
  align-items: center;
  padding: 0;
  margin: 0;
  width: 50px;
  max-height: 50px;
  margin-left: 15px;
  display: inline-block;
}

.navBar > ul > #homeLI > a {
  max-height: 50px;
}

.navBar > ul > #homeLI #home #logo {
  width: 50px;
  margin: 0;
  padding: 0;
  max-height: 50px;
  display: inline-block;
}

.navBar > ul > #homeLI #home #logo:hover {
  transform: scale(1.2);
}

.navBar > ul > .links {
  align-items: center;
  width: 50%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.navBar > ul > .links > li:hover {
  transform: scale(1.2);
}

.navBar > ul > .links > li a {
  color: #f1f1f1;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .navBar > ul > .links {
    padding: 0;
    margin: 0;
    width: 90vw;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .navBar > ul > .links {
    padding: 0;
    margin: 0;
    width: 70vw;
  }
}
/*# sourceMappingURL=Nav.css.map */
#socials {
  display: flex;
}

#socials > li > a > .socialIcon {
  font-size: 2em;
  margin-right: 10px;
  color: #f1f1f1;
}

#socials > li > a > .socialIcon:hover {
  transform: scale(1.2);
}
/*# sourceMappingURL=socialMedia.css.map */
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/
@font-face {
  font-family: proxima;
  src: url(/static/media/ProximaNova-Regular.410504d4.otf);
}

* {
  box-sizing: border-box;
}

#contactContainer {
  min-height: 100vh;
  max-width: 100vw;
  padding: 5% 0;
  overflow: hidden;
  display: -ms-grid;
  display: grid;
      grid-template-areas: "logo cta ." "  .  cta contact";
  -ms-grid-rows: 2fr 1fr;
  grid-template-rows: 2fr 1fr;
  -ms-grid-columns: 1fr 2fr 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  position: relative;
}

#contactContainer .content {
  font-family: proxima, sans-serif;
}

#contactContainer .titles {
  font-family: "Krona One", sans-serif;
}

#contactContainer > .logo {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: logo;
  max-width: 175px;
  padding: 0;
  margin: 0 auto;
  z-index: 1;
  margin-top: 50px;
}

#contactContainer > #info {
  height: 150px;
  color: #f1f1f1;
  text-align: left;
  margin: 0 auto;
  -ms-grid-row: 2;
  -ms-grid-column: 3;
  grid-area: contact;
  z-index: 1;
}

#contactContainer > #info > span {
  margin-bottom: 10px;
}

#contactContainer #backgroundImg:before {
  content: "";
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  background-color: #0d1128;
  top: 0;
  left: 0;
  opacity: 0.87;
  z-index: 0;
}

#contactContainer > #backgroundImg {
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  min-width: 100vw;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

#callToAction {
  z-index: 1;
  margin: 0 auto;
  padding: 0;
  width: 50vw;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  grid-area: cta;
  font-size: 1.25em;
  margin-top: 50px;
}

#callToAction > p,
#callToAction h1,
#callToAction label {
  color: #f1f1f1;
}

#callToAction hr {
  width: 75%;
  margin: 0 auto;
}

#callToAction #desc {
  margin: 5vh 0 2vh 0;
  text-align: justify;
  font-size: 1em;
}

#callToAction #inputs {
  padding-bottom: 15px;
}

#callToAction #inputs > label {
  text-align: left;
}

#callToAction #inputs input {
  width: 100%;
  height: 2rem;
  font-size: 1em;
  display: inline-block;
  border: none;
  background: #f1f1f1;
  overflow: auto;
  padding: 2px;
  color: black;
  margin: 5px 0 10px 0;
  border-radius: 5px;
}

#callToAction #inputs textarea[name="body"] {
  width: 100%;
  height: 4rem;
  overflow: auto;
  resize: none;
  padding: 3px;
  font-size: 1em;
  margin: 5px 0 10px 0;
  border-radius: 5px;
  height: 150px;
}

#callToAction #inputs > button {
  height: 2.5em;
  color: #000;
  font-size: 0.8em;
  border-radius: 5px;
  width: 150px;
  font-size: 1.2rem;
  font-weight: 800;
  z-index: 2;
  position: relative;
  background-color: #85dbd9;
  border-radius: 5px;
  font-family: 'Krona One', sans-serif;
}

#callToAction #inputs > button:hover {
  transform: scale(1.1);
  border: 1px solid #f1f1f1;
}

@media only screen and (max-width: 1000px) {
  #contactContainer {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  #contactContainer > #logo {
    margin: 0;
    padding: 0;
  }
  #contactContainer > #callToAction {
    width: calc(100vw - 2em - 10%);
    flex: 1 1;
    margin-top: 0;
  }
  #contactContainer > #callToAction > h1 {
    font-size: 1.9em;
  }
  #contactContainer > #info {
    height: 90px;
    padding: 0;
    width: calc(100vw - 2em - 10%);
    line-height: 1.5em;
  }
}
/*# sourceMappingURL=Contact.css.map */
@font-face {
  font-family: proxima;
  src: url(/static/media/ProximaNova-Regular.410504d4.otf);
}

.modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.modal .modalContainer {
  box-shadow: -3px 3px 2px #41d3ff;
  border-radius: 15px;
  background-color: #eef8eb;
  width: 80vw;
  height: auto;
  margin: 0 auto;
  padding: 0 20px;
}

.modal .modalContainer > .modalTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  font-weight: 800;
  color: #1b4a56;
}

.modal .modalContainer > .modalContent {
  text-align: justify;
  color: #1b4a56;
  font-family: proxima, cursive;
}

.modal .modalContainer > .modalSocials {
  font-size: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 0;
}

.modal .modalContainer > .modalSocials > li > a {
  color: #1b4a56;
}

.modal .modalContainer > ul {
  list-style: none;
  margin: 0;
  padding: 15px 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}

.modal .modalContainer > ul > li {
  color: #1b4a56;
  margin-right: 10px;
  font-family: proxima, cursive;
  font-weight: 800;
}

@media only screen and (max-width: 600px) {
  .modal .modalContainer > .modalTitle {
    font-size: 2rem;
  }
}
/*# sourceMappingURL=ProjectModal.css.map */
.projects {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.projects .outerContainer {
  margin: auto 0;
}

.projects .outerContainer > h1 {
  color: white;
  font-size: 3rem;
  font-family: "Krona One", sans-serif;
}

.projects .outerContainer > ul {
  color: white;
  list-style: none;
  margin: 0;
  padding: 0;
}

.projects .outerContainer > ul > li {
  width: 280px;
  height: 195px;
  border: 1px solid #fff;
}

.projects .outerContainer .projectsContainer {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  width: 90%;
  padding: 0 20px;
  margin: 0 auto;
}

.projects .outerContainer .projectsContainer #TFTP,
.projects .outerContainer .projectsContainer #jobHunt,
.projects .outerContainer .projectsContainer #PMS,
.projects .outerContainer .projectsContainer #msgBoard,
.projects .outerContainer .projectsContainer #noShot,
.projects .outerContainer .projectsContainer #redo {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects .outerContainer .projectsContainer #TFTP > button,
.projects .outerContainer .projectsContainer #jobHunt > button,
.projects .outerContainer .projectsContainer #PMS > button,
.projects .outerContainer .projectsContainer #msgBoard > button,
.projects .outerContainer .projectsContainer #noShot > button,
.projects .outerContainer .projectsContainer #redo > button {
  opacity: 0;
  height: 40px;
  z-index: 2;
  padding: 10px;
  color: #fff;
  background-color: #1b4a56;
  border-radius: 5px;
  font-family: "Krona One", sans-serif;
}

.projects .outerContainer .projectsContainer #noShot:hover button,
.projects .outerContainer .projectsContainer #TFTP:hover button,
.projects .outerContainer .projectsContainer #jobHunt:hover button,
.projects .outerContainer .projectsContainer #PMS:hover button,
.projects .outerContainer .projectsContainer #msgBoard:hover button,
.projects .outerContainer .projectsContainer #redo:hover button {
  opacity: 1;
}

.projects .outerContainer .projectsContainer #noShot:hover:after,
.projects .outerContainer .projectsContainer #TFTP:hover:after,
.projects .outerContainer .projectsContainer #jobHunt:hover:after,
.projects .outerContainer .projectsContainer #PMS:hover:after,
.projects .outerContainer .projectsContainer #msgBoard:hover:after,
.projects .outerContainer .projectsContainer #redo:hover:after {
  content: "";
  position: absolute;
  background-color: rgba(238, 248, 235, 0.6);
  z-index: 1;
  width: 280px;
  height: 195px;
}

.projects .outerContainer .projectsContainer #TFTP {
  background-image: url(/static/media/tftp2.c4b08193.png);
}

.projects .outerContainer .projectsContainer #jobHunt {
  background-image: url(/static/media/jobHunt.1ceeed2b.png);
}

.projects .outerContainer .projectsContainer #PMS {
  background-image: url(/static/media/PMS.234d75df.png);
}

.projects .outerContainer .projectsContainer #msgBoard {
  background-image: url(/static/media/msgBoard.45c69ab8.png);
}

.projects .outerContainer .projectsContainer #noShot {
  background-image: url(/static/media/noShotv2.bf85e360.png);
}

.projects .outerContainer .projectsContainer #redo {
  background-image: url(/static/media/redo.cd3fb035.png);
}

@media only screen and (max-width: 990px) {
  .projects .outerContainer .projectsContainer {
    justify-content: center;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .projects .outerContainer .projectsContainer {
    width: 80%;
  }
}
/*# sourceMappingURL=Projects.css.map */
@font-face {
  font-family: proxima;
  src: url(/static/media/ProximaNova-Regular.410504d4.otf);
}

.strike {
  text-decoration: line-through;
}

.skills {
  display: flex;
  flex-direction: column;
  position: relative;
}

.skills #skillTitle {
  color: white;
  padding-top: 25px;
  font-size: 3rem;
  font-family: "Krona One", sans-serif;
}

.skills .hide {
  opacity: 0.5 !important;
}

.skills .wordSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-content: center;
  align-self: center;
  font-family: proxima, cursive;
  z-index: 2;
  opacity: 1;
}

.skills .wordSearch > .innerContainer {
  display: flex;
}

.skills .wordSearch > .innerContainer > .wordSearchContainer {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 15px;
}

.skills .wordSearch > .innerContainer > ul {
  color: white;
  list-style: none;
}

.skills .wordSearch > .innerContainer > .skillGroupContainer {
  padding-left: 15px;
}

.skills #letter {
  width: 25px;
  height: 25px;
  font-size: 0.75rem;
  margin: 1px;
  border-radius: 100%;
  border: none;
  background-color: #fff;
  color: #000;
}

.skills #letter:hover {
  background-color: #ff0;
}

.skills .titleSkills {
  color: #85dbd9;
  margin: 0;
  text-align: left;
}

.skills .skillsList {
  margin: 0;
  padding: 0;
  text-align: left;
  color: #fff;
  list-style: none;
}

@media only screen and (max-width: 800px) {
  .skills .wordSearch > .innerContainer {
    flex-direction: column;
  }
  .skills .wordSearch > .innerContainer > .wordSearchContainer {
    padding: 5px;
  }
  .skills .wordSearch > .innerContainer > .skillGroupContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 30px;
    justify-content: space-around;
    height: 300px;
  }
  .skills .wordSearch > .innerContainer > .skillGroupContainer > .skillGrouping {
    padding: 0 15px;
    min-width: 150px;
  }
  .skills #letter {
    width: 23px;
    height: 23px;
  }
  .skills .titleSkills {
    text-align: center;
  }
  .skills .skillsList {
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .skills .wordSearch .wordSearchContainer .wordSearchGrid {
    display: none;
  }
}
/*# sourceMappingURL=Skills.css.map */
.celebrate {
  display: inline !important;
}

.celebration {
  background-repeat: no-repeat;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: none;
  opacity: 0.9;
}

.celebration > .confetti {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  opacity: 0.9;
  z-index: 10;
}

.celebration > h1 {
  color: #fff;
  position: relative;
  display: flex;
  font-size: 9em;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
  z-index: 222;
}

.celebration > h1 > p {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 0.4em;
  z-index: 222;
  position: relative;
}
/*# sourceMappingURL=celebrate.css.map */
@font-face {
  font-family: proxima;
  src: url(/static/media/ProximaNova-Regular.410504d4.otf);
}

.about {
  color: #fff;
  display: flex;
  margin: auto 30px;
  min-height: 100vh;
  align-items: center;
}

.about .aboutContainer {
  display: flex;
  align-items: flex-end;
  margin: 0 auto;
}

.about .aboutContainer .textContainer {
  text-align: justify;
  margin-left: 15px;
  position: relative;
  width: 85%;
  display: flex;
  flex-direction: column;
}

.about .aboutContainer .textContainer > h1 {
  font-size: 3rem;
  margin: 0;
  padding: 0;
  font-family: 'Krona One', sans-serif;
}

.about .aboutContainer .textContainer > p {
  font-size: 1.1rem;
  font-family: proxima, cursive;
}

.about .aboutContainer .textContainer > .connectCTA > button {
  padding: 10px;
  font-size: 1rem;
  align-self: flex-start;
  margin: 0;
  font-weight: 600;
  font-family: proxima, cursive;
  background-color: #85dbd9;
  border-radius: 5px;
  color: #000;
}

.about .aboutContainer .textContainer > .connectCTA > button:hover {
  background-color: #fff;
}

.about .aboutContainer .textContainer > .hobbies {
  width: 300px;
  height: 45px;
  margin-left: auto;
}

@media only screen and (max-width: 900px) {
  .about .aboutContainer {
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: center;
    padding: 50px 0 30px 0;
  }
  .about .aboutContainer > img {
    width: 300px;
    height: 300px;
  }
}
/*# sourceMappingURL=About.css.map */
@font-face {
  font-family: proxima;
  src: url(/static/media/ProximaNova-Regular.410504d4.otf);
}

#home {
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

#home > .homeContainer {
  margin: auto 0;
  padding-top: 50px;
}

#home > .homeContainer > h1 {
  font-size: 4rem;
  margin: 0;
  padding: 0;
  text-shadow: -2px 1px rgba(255, 255, 0, 0.4);
  font-family: 'Krona One', sans-serif;
  font-weight: 600;
}

#home > .homeContainer > h2 {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: 'Comfortaa', cursive;
  margin-bottom: 85px;
}

#home > .homeContainer > .role:after {
  content: "";
  height: 100%;
  width: 1px;
  border-right: 2px solid #fff;
  margin-left: 1px;
  -webkit-animation: blink 0.6s infinite ease;
  animation: blink 0.6s infinite ease;
  position: relative;
}

@-webkit-keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#home > .homeContainer > .cta {
  font-family: proxima, cursive;
}

#home > .homeContainer > .cta .ctaButton > button {
  padding: 10px;
  width: 150px;
  font-size: 1.2rem;
  font-weight: 800;
  z-index: 2;
  position: relative;
  background-color: #85dbd9;
  border-radius: 5px;
  color: #000;
}

#home > .homeContainer > .cta .ctaButton > button:hover {
  background-color: white;
}

#home .triangles {
  position: absolute;
  bottom: -420px;
  right: -155px;
  padding: 0;
  margin: 0;
  z-index: 0;
}
/*# sourceMappingURL=Home.css.map */
