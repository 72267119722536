/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Comfortaa:wght@300&family=Krona+One&display=swap&family=Montserrat:wght@600&display=swap');

.App {
  text-align: center;
  background-color: rgb(13,17,40);
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  min-height:100vh;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding:0;
  margin:0;
}
.App:hover{
  cursor:default;
}
hr{
  width:100%;
  margin:0;
}